import React from 'react'
import { PageProps } from 'gatsby'
import { Layout } from '../../components/layout'
import { SEO } from '../../components/seo'
import { Menu } from '../../components/menu'

import * as styles from '../../styles/pages/solutions.module.scss'
import mainHeader from '../../images/solutions/sgiMain.jpg'

import { Contact } from '../../components/contact/contact'
import { Footer } from '../../components/footer'
import { FaqSection } from '../../components/faq'
import clsx from 'clsx'
import { MethodologySection } from '../../components/methodology'
import { SliderCustomers } from '../../components/sliderCustomers/sliderCustomers'
import { StatisticsSection } from '../../components/statistics'
import { sgiList } from '../../utils/customersList'

const faqItems = [
  {
    question: 'A High Jump é uma empresa certificadora?',
    answer:
      'A High Jump NÃO é uma empresa certificadora. A nossa solução é prestar uma Consultoria de Implantação dos Sistemas de Gestão ISO, preparando a empresa para que esteja apta a passar por uma auditoria de certificação, que é realizada por uma certificadora acreditada.',
  },
  {
    question: 'Por que ter uma Certificação ISO?',
    answer:
      'A conquista de uma Certificação ISO traz muitos benefícios para a empresa. Dentre eles podemos citar: melhoria na satisfação dos clientes; impacto positivo na credibilidade da marca; crescente na satisfação e motivação dos colaboradores; redução de custos; aumento de oportunidades de negócio e expansão de mercado e o atendimento às exigências de grandes clientes.',
  },
  {
    question: 'Quanto tempo leva a obtenção da Certificação ISO?',
    answer: (
      <>
        O prazo é variável, pois depende do escopo de certificação, porte da
        empresa e maturidade dos processos. Porém, em média, essas previsões são
        estimadas:
        <br />
        Implantação do sistema de gestão: 6 a 12 meses;
        <br />
        Auditoria externa: de 3 a 5 dias;
        <br />
        Emissão do certificado: 1 mês.
        <br />
        Validade do certificado: 3 anos.
      </>
    ),
  },
  {
    question: 'Quanto custa a Consultoria para Implantação ISO?',
    answer:
      'O custo da Consultoria para Implantação ISO é variável. Para realizar a estimativa de custo é necessário considerar o escopo de implantação, a quantidade de funcionários, plantas a serem certificadas e a modalidade de atendimento, se presencial ou remota.',
  },
]

const featuresItems = [
  {
    title: `Diagnóstico de Aderência às Normas`,
    description: (
      <>
        Fazemos um diagnóstico preciso com base na norma desejada, e fornecemos
        o nível de aderência da sua organização com relação aos itens
        normativos, bem como um plano de ação estruturado que direcione os seus
        esforços na busca pela obtenção da certificação ISO.
      </>
    ),
  },
  {
    title: `Treinamentos`,
    description: (
      <>
        Fornecemos treinamentos de introdução e interpretação das normas de
        gestão ISO e preparação de auditores líderes, de acordo com o escopo de
        certificação (ISO 9001, ISO 14001, ISO 45001).
      </>
    ),
  },
  {
    title: `Implementação de normas de gestão ISO`,
    description: (
      <>
        Fazemos o acompanhamento de toda a preparação para a certificação ISO,
        desde o diagnóstico até a implementação de todas as ações para obtenção
        do certificado, com foco em padronização e melhoria de processos
        sustentáveis.
      </>
    ),
  },
  {
    title: `Auditoria Interna`,
    description: (
      <>
        Fazemos a auditoria interna de conformidade do sistema de gestão, que é
        item normativo obrigatório, para avaliação do atendimento aos requisitos
        e proposição de ações corretivas e/ou de melhoria. Atuamos em todas as
        etapas: planejamento, execução e encerramento.
      </>
    ),
  },
]

const SgiPage: React.FC<PageProps> = ({ location }) => {
  const methodologyItems = [
    {
      count: 1,
      title: 'Planejamento',
      description: (
        <>
          A fase do planejamento é um processo comercial. Neste momento ainda
          não houve a contratação do serviço. O objetivo desta etapa é alinhar
          todas as expectativas do projeto e os objetivos junto ao cliente.
        </>
      ),
    },
    {
      count: 2,
      title: 'Diagnóstico',
      description: (
        <>
          Nessa etapa, coletamos informações através de entrevistas com os
          pontos focais de cada processo. São registradas as oportunidades de
          melhoria e as não conformidades, ou seja, situações em desacordo com
          as normas, leis, processos e procedimentos.
        </>
      ),
    },
    {
      count: 3,
      title: 'Análise e Melhoria',
      description: (
        <>
          Após concluído o diagnóstico, será apresentado um relatório que
          demonstra o nível de maturidade da organização em relação aos
          requisitos normativos, bem como um plano de ação que direcione os
          esforços para obtenção da certificação, dentro do prazo pretendido
          pelo cliente.
        </>
      ),
    },
    {
      count: 4,
      title: 'Implementação',
      description: (
        <>
          Acompanhamos a implementação das ações para adequação dos processos
          aos requisitos normativos. Acompanhamos também a auditoria externa e
          fornecemos as diretrizes para manutenção da certificação.
        </>
      ),
    },
  ]

  return (
    <Layout location={location}>
      <SEO title="High Jump - Sistema de Gestão Integrado" />
      <div className={styles.solutions}>
        <Menu mainFolder="SGI" />
        <main>
          <section className={clsx(styles.intro, styles.singleTitle)}>
            <img src={mainHeader} alt="High jump Consulting" />
            <div className={clsx(styles.mainTitle)}>
              <h1>
                Eleve a qualidade, confiabilidade e autoridade da sua marca
              </h1>
              <p>
                Conheça as nossas soluções para implementação de sistemas gestão
                para obtenção das principais certificações ISO do mercado -
                9001, 14001 e 45001.
              </p>
            </div>
          </section>
          <div className={styles.container}>
            <div className={styles.intention}>
              <div className={styles.title}>
                <h1>Nosso Propósito</h1>
                <span></span>
                <p>
                  Adequar processos de negócio para obtenção das principais
                  certificações ISO do mercado (ISO 9001, ISO 14001 e ISO
                  45001), consolidando empresas com padrões de qualidade, gestão
                  ambiental e saúde e segurança ocupacional.
                </p>
              </div>
              <div className={styles.mainFeatures}>
                <div className={styles.listItems}>
                  {featuresItems.map((item, key) => (
                    <div className={styles.item} key={key}>
                      <h4>{item.title}</h4>
                      <p>{item.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <MethodologySection
              items={methodologyItems}
              descriptionSection="Conheça os 04 principais passos de nossa metodologia para conquistar uma certificação ISO."
            />
          </div>

          <div className={styles.cases}>
            <div className={styles.title}>
              <h1>Quem conhece, confia!</h1>
            </div>
            <SliderCustomers customers={sgiList} />
          </div>

          <div className={styles.statistics}>
            <StatisticsSection />
          </div>

          <div className={styles.container}>
            <div className={styles.features}>
              <div className={styles.title}>
                <h1>Diferenciais</h1>
              </div>
              <div className={styles.items}>
                <div className={styles.div1}>
                  <h2>Equipe multidisciplinar</h2>
                  <p>
                    Especialistas por área, trazendo as melhores práticas de
                    mercado
                  </p>
                </div>
                <div className={styles.div2}>
                  <h2>Certificações</h2>
                  <p>Auditores líderes certificados IRCA</p>
                </div>
                <div className={styles.div3}>
                  <h2>Documentação completa</h2>
                  <p>Fornecemos modelos, manuais e procedimentos</p>
                </div>
                <div className={styles.div4}>
                  <h2>Cronograma personalizado</h2>
                  <p>Para atender o prazo solicitado pelo cliente</p>
                </div>
              </div>
            </div>
          </div>

          <Contact />
          <FaqSection items={faqItems} />
          <Footer />
        </main>
      </div>
    </Layout>
  )
}

export default SgiPage
